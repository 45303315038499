import axios from 'axios';

axios.interceptors.response.use(undefined, function axiosRetryInterceptor(err) {

    let error = {status: 0, message: 'Error desconocido'}
    if (err.message === 'Network Error' && !err.request){
        error.status = 0;
        error.message = 'Error de red';
    }
    else if(err.request){
        error.status = err.request.status;
        if (err.request.status === 401){
            error.message = 'Credenciales inválidas';
        }
        else if (err.request.status === 403){
            error.message = 'No autorizado';
        }
        else if (err.request.status === 0){
            error.message = 'Error en la red';
        }
        else{
            error.message = err.message;
        }
    }

    return Promise.reject(error);
});

axios.interceptors.request.use(function (config) {
  if (config.method.toLocaleUpperCase() === "GET") {
    if (config.data) {
      if (!config.params){
        config.params = JSON.parse(config.data);
      }
    }
  }
  return config;
}, function (error) {
  return Promise.reject(error);
});


export default class ApiLoad {

    static auth = (url, credentials) => {
        return new Promise((resolve, reject) => {

            axios.post(url, credentials).then((response) => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });

        });
    }

    static getWithBasic = (url, data,  basic) => {

        return new Promise((resolve, reject) => {

            axios.get(url, data, basic).then((response) => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });

        });
    }

    static requestWithBearer = (url, method='post', data = {},  token = null) => {

        return new Promise((resolve, reject) => {

            axios.request({
                url
                ,method
                ,data: JSON.stringify(data)
                ,headers: {
                    "Authorization" : `Bearer ${token}`
                    ,'content-type': 'text/json'
                }
            }).then((response) => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });

        });
    }

    static simpleRequest = (url, method='get', data = {}) => {

        return new Promise((resolve, reject) => {

            axios.request({
                url
                ,method
                ,data: JSON.stringify(data)
                ,headers: {
                    'content-type': 'text/json'
                }
            }).then((response) => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });

        });
    }

    static getWithExpiry = (key) => {
      const itemStr = localStorage.getItem(key)
      if (!itemStr) {
          return null
      }

      const item = JSON.parse(itemStr)
      const now = new Date()

      if (now.getTime() > item.expiry) {
          localStorage.removeItem(key)
          return null
      }
      return item.value
  }

  static setWithExpiry = (key, value, ttl) => {
    const now = new Date()

    const item = {
        value: value,
        expiry: now.getTime() + ttl,
    }
    localStorage.setItem(key, JSON.stringify(item))
  }

}
